import React from "react"
import loadable from "@loadable/component"
import { makeStyles } from "@material-ui/core/styles"
import "../../css/typography-muli.css"
const ChatComponent = loadable(() =>
  import("../../components/prod/custom-page")
)

const useStyles = makeStyles({
  circle: {
    position: "absolute",
    height: "385px",
    width: "489px",
    bottom: 0,
    right: "2%",
  },
  dots: {
    position: "absolute",
    left: "10%",
    top: "100px",
  },
})

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  const classes = useStyles()

  return (
    <ChatComponent
      name="Testkit Assistent"
      pageTitle="Convaise - Testkit"
      // homepage="#"
      // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c" // directline bot-demos-prod-02
      // token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s"
      // courier (prod)
      // token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODA3Njc3MDQuMjc4MDY1LCJleHAiOjIzMTE0ODc3MDQuMjc4MDY1LCJib3RJZCI6IjFjNm85OWJza3J1eGdhcTIiLCJpc3MiOiJjb252YWlzZSIsImF1ZCI6ImNvbnZhaXNlLWNvdXJpZXIifQ.U3nTK1pRTLsn721Ll8OrnzcSwL6CmSJV54QSRqDrFczICS5b493UgQukykC3kAxPMNm7lugoBvNBj7tzhcusyXiE7iHZyrxXcm2TEwVzwR8lgLysrLfJssxEz3IvG4lNTzEVDh_jFQSnkSJ5MXgT3yIuLwatey5GEGY67Vr-ZDanzbkx3cxgbsDy4cyzq-_26y55jk9MOKOVuS6U5_eo9C1RdPoxHWWNsQKNh1JpoM2766JIWPXFlD_wOq1h7LWcmaDx5NZw25BLLLUNkYxVF9TD85cTaI8ZZL2S9RaTP9wMfb1mBvOA2QNXc-rLXn_mmkwe_bC_FZJGS-isX1xqCGccaneGPs2t5bmMM0H0eiHlPlLdkE0z4fovZF68UE3uTV-wE8YWMar0_XSZQPzM5_ClGWBlzMloKkPHWarbAS5orGt42y97ek7r904J6UXRKuhjDkT2hAYZ970HyNAIHHbaZWrQoby7ka4BnAh_dPhqO9gKsKAwPNYq4T9TPWa2NJ4tJHJw7n97fIbBMjl-CxluxiNimNm5GsWeXhx1dthTCPcIzHtWyuoemht2DxAKIsdWg0MEtzxsyitx0E6yscRlyleJSdeEKaddK9m3qJdz6Dtiz1nO_98ATDhvH7qhxwne83llXTkonUz5ObHTGbD8Sy85915TJDPEPwk_dlw" // courier
      // courier (dev + local)
      token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODM4ODU1OTMuMjgwNjA1LCJleHAiOjIzMTQ2MDU1OTMuMjgwNjA1LCJib3RJZCI6IjFjNm85OWJza3J1eGdhcTIiLCJpc3MiOiJjb252YWlzZSIsImF1ZCI6ImNvbnZhaXNlLWNvdXJpZXIifQ.ZsfZHc3hF2UUULZVHGx1RSa_UNs9hPZTTkPWfL6Ch_tsvPeWEA7WbJCK21Gcv5oSKH74UHqi2esPWv1_z5bREF7qix7YNmdmAYgP3QkuelgGDyTn0EiJWlsoCmo_4cxykvc6xW6Ag6n4QAPja0nZG8ep91K4Uk2wZSMeCjJtlXP2CEOHZ7QDqHc9DFASaQJyXnw3uPjzvNKZA-B0JPspj1BIkrV8spYfhE_kg32AudXnbxaIZoEvCBIAdjo0d2jcDMjuzNNfFolTYmU6C-huRDtHOJ393RAJyUA5KGcsptUA6BPN6oVDJwpWBX7Rzjrooqt-bAsOO2VOsnum6Ka4uFi0pTBs9XWWBuDj8I458Nytep3P2dUpXAk8416UMetZaYTEOCVtFoa73BwJM-bmaPpOPYBZ4h4H2suleKtuvJTLxTruilZzZlZeSTNjUuDOIGtKO9I9tdkTykJrBvG4NU_gk3RIX1aQkffS928IzlkO01RPEAVjWa-YUnh4EsZWZWgR4qGBvm4VWK13jAYdh3TvU2RC9LOrJhNyzzbW_P-CddRyYoKfJ6e7KYVJmXLigK7i0mD7ArXikjuShkXO3ukBTIBdW8pH3YSPltSRb1LlcmBx1KpaZ6bkLiGoaEfhZIX-8VKfp4MoJknH7oSbUfukYXeL0KAyV_BwosJqTpk"
      botId="1c6o99bskruxgaq2"
      avatar={false}
      avatarInitials={false}
      logo="https://static.convaise.com/convaise-logo.svg"
      // domain="https://europe.directline.botframework.com/v3/directline"
      domain="https://courier.convaise.com/frontend/v3/directline"
      // domain="http://localhost:8000/v3/directline"
      styleOptions={{
        primaryFont: "'Noto Sans', 'sans-serif'",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#394d75",
        botAvatarBackgroundColor: "#fff",
        // primaryFont:
        //   '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      // styleCustomizations={{
      //   "--button-blue": "#551285",
      //   "--button-blue-light": "#77419D",
      //   "--button-orange": "rgba(255, 166, 0, 1)",
      //   "--button-orange-light": "rgb(255, 182, 46)",
      // }}
      backgroundColor="#F0F4F5"
      customs={[
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          className={classes.circle}
          role="presentation"
          alt=""
          key="customization-circle"
        />,
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          className={classes.dots}
          key="customization-dots"
          role="presentation"
          alt=""
        />,
      ]}
      // css="https://static.convaise.com/webchat/gegenbauer/convaise-assistant-gegenbauer-iframe-min.css"
    />
  )
}

export default Chat
